
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.popular-service {
  position: relative;
  border-radius: $base-border-radius * 1.5;
  padding: 16px;
  background: $color-white;
  transition: box-shadow $base-animation;
  display: inline-block;
  width: 100%;
  height: 100%;

  @include for-size(phone-portrait-down) {
    padding-right: 12px;
  }

  &__icon {
    width: 48px;
    height: 48px;
    background: rgba($color-yellow-status, 0.1);
    border-radius: $base-border-radius * 2;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: $base-gutter;

    .esmp-icon {
      width: 24px;
      fill: $color-yellow-status;
    }
  }

  &__name {
    font-weight: 500;
    color: $color-black;
    margin-bottom: $base-gutter;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-box;
    -moz-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-clamp: 2;
    box-orient: vertical;
  }

  &__description {
    font-weight: 400;
    color: $color-black-op-50;
  }

  &:hover {
    box-shadow: $base-shadow-m;
  }
}
